import ORMModel from '@/core/bridge/orm/ORMModel';
import UserV1RoleCoreModel from '@/modules/users_v1/models/UserV1RoleCoreModel';
import UserV1CorePermissionModel from '@/modules/users_v1/models/UserV1CorePermissionModel';
import UserV1HasPermissionCoreModel from '@/modules/users_v1/models/UserV1HasPermissionCoreModel';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class UserV1CoreModel extends ORMModelExtended {
  public static entity = 'users-v1';

  public static methodConf = {
    http: {
      url: '/v2/users', // TODO add APP_URL
    },
  };

  public static fields() {
    return {
      id: this.number(null),
      login: this.string(''),
      role_id: this.number(null),

      role: this.belongsTo(UserV1RoleCoreModel, 'role_id'),
      permissions: this.belongsToMany(UserV1CorePermissionModel, UserV1HasPermissionCoreModel, 'user_id', 'permission_id'),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public login!: string;

  public role!: UserV1RoleCoreModel;
  public permissions!: UserV1CorePermissionModel[];

  /**
   * Check if user is admin
   */
  public isAdmin(): boolean {
    return true; // TODO
  }

  /**
   * Check if user has permission
   * @param permissionName
   */
  public hasPermission(permissionName: string): boolean {
    return !!this.permissions.find((permission: UserV1CorePermissionModel) => permission.name === permissionName);
  }
}
